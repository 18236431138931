<template>
  <div id="app" class="bg-image">
     <notifications />
    <div class="cube-loading" v-if="$store.state.showLoading">
      <div class="sk-cube-grid mvc-loader">
        <div class="sk-cube sk-cube1"></div>
        <div class="sk-cube sk-cube2"></div>
        <div class="sk-cube sk-cube3"></div>
        <div class="sk-cube sk-cube4"></div>
        <div class="sk-cube sk-cube5"></div>
        <div class="sk-cube sk-cube6"></div>
        <div class="sk-cube sk-cube7"></div>
        <div class="sk-cube sk-cube8"></div>
        <div class="sk-cube sk-cube9"></div>
      </div>
      <div class="loader-txt">Loading data...</div>
    </div>
    <div
      class="app-header main row between-xs top-bar"
      v-if="isAuthenticated"
      id="header"
    >
      <div class="col-xs-2 p0">
        <a href="/">
          <img :src="`/assets/img/adready-plus.webp`" class="logo" />
        </a>
      </div>
      <div class="col-xs">
        <div class="nav-right">
          <ul class="row end-xs nav-icons middle-xs">
            <li>
              <a href="/"> Home </a>
            </li>
            <li>
              <a href="/plan-create"> Plan </a>
            </li>
            <li>
              <a href="/reporting"> Reporting </a>
            </li>
            <li v-if="isAdmin">
              <a href="#" @click="showAddPixelModal"> Pixel </a>
            </li>
            <li>
              <a href="#" @click="showSegmentModal"> Segments </a>
            </li>
            <li>
              <a href="#" @click="showPMPModal"> Content Vertical List </a>
            </li>
            <li v-if="isRateCardEditor">
              <a href="#" @click="showRateCardModal"> Rate Card </a>
            </li>
            <li>
              <a href="/logout">
                <font-awesome-icon
                  :icon="['fal', 'sign-out']"
                ></font-awesome-icon>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="router-view">
      <router-view />
    </div>
    <div class="row center-xs">
      <div class="footer">
        <img :src="`/assets/img/logos-light.webp`" />
      </div>
    </div>
  </div>
</template>

<script>
import { get } from "vuex-pathify";
import { mapState, mapGetters } from "vuex";

const SegmentModal = () => import("@/components/SegmentModal");
const PixelModal = () => import("@/components/PixelModal");
const PMPAllowListModal = () => import("@/components/PMPAllowListModal");
const RateCardModal = () => import("@/components/RateCardModal");
import { isBlank } from "adready-api/helpers/common";
import { RATE_CARD_WRITE, ROLE_ADMIN } from "./constant";
import authzMixin from "./mixin/authz-mixin";

export default {

  mixins: [authzMixin],

  computed: {
    ...mapState({
      token: (state) => state.authentication.token,
    }),
    ...mapGetters({
      isAuthenticated: "authentication/isAuthenticated",
    }),
    authentication: get("authentication"),

    isAdmin() {
      if (this.authentication && !isBlank(this.authentication.accounts)) {
        const account = this.authentication.accounts[0];
        if (account.role.id === ROLE_ADMIN) {
          return true;
        }
      }
      return false;
    },

    isRateCardEditor() {
      return this.hasPermission(RATE_CARD_WRITE);
    },
  },

  created() {
    const token = localStorage.getItem("token");

    if (token !== null) {
      this.$store.dispatch("authentication/decodeToken", token).then(() => {});
    }
  },
  mounted() {
    if (this.$router.currentRoute.path === "/reporting") {
      document.getElementById("app").classList.remove("bg-image");
    }
    if (this.$router.currentRoute.path === "/plan-create") {
      let elements = document.getElementsByClassName("footer");
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
    }

    const $header = document.getElementById("header");
    window.onscroll = () => {
      let bottomBarPosition = $header.offsetTop;

      if (window.pageYOffset > bottomBarPosition) {
        $header.classList.add("fixed");
      } else {
        $header.classList.remove("fixed");
      }
    };
  },
  methods: {
    showAddPixelModal() {
      this.$modal.show(PixelModal, {}, { height: "600px", width: "800px" });
    },

    showSegmentModal() {
      this.$modal.show(SegmentModal, {}, { height: "750px", width: "800px" });
    },
    showPMPModal() {
      this.$modal.show(
        PMPAllowListModal,
        {},
        { height: "600px", width: "800px" }
      );
    },
    showRateCardModal() {
      this.$modal.show(
        RateCardModal,
        {},
        {
          overflow: "auto",
          height: "80%",
          left: "200px",
          width: "900px",
          scrollable: true,
        }
      );
    },
  },
};
</script>

<style lang="scss">
.vm--modal {
  overflow: auto !important;
}
.cube-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 9999;

  display: block;

  margin-top: -150px;
  margin-left: -36px;
}
.sk-cube-grid {
  width: 50px;
  height: 50px;
  margin: 30px auto;
}

.sk-cube-grid .sk-cube {
  float: left;

  width: 33%;
  height: 33%;

  background-color: #28a8b5;
  background-color: #fdce07;

  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid {
  .sk-cube1 {
    animation-delay: 0.2s;
  }
  .sk-cube2 {
    animation-delay: 0.3s;
  }
  .sk-cube3 {
    animation-delay: 0.4s;
  }
  .sk-cube4 {
    animation-delay: 0.1s;
  }
  .sk-cube5 {
    animation-delay: 0.2s;
  }
  .sk-cube6 {
    animation-delay: 0.3s;
  }
  .sk-cube7 {
    animation-delay: 0s;
  }
  .sk-cube8 {
    animation-delay: 0.1s;
  }
  .sk-cube9 {
    animation-delay: 0.2s;
  }
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }

  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }

  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
.loader-txt {
  margin: 20px 0;

  font-size: 14px;
  text-align: center;

  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    color: #ccc;
  }

  50% {
    color: #333;
  }

  100% {
    color: #ccc;
  }
}
</style>
