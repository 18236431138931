// Rate Card Item - Rate Type
export const RT_COST_PLUS = "COSTPLUS";
export const ROLE_ADMIN = 3;
export const ACCOUNT_TYPE_PLUS = "plus";

// Permission constants...
export const RATE_CARD_WRITE = "rate_card_write";

export const ADVERTISER_TYPE = "advisr";

export const ADREADY_ACCOUNT_MANAGER_ROLE_ID = 1;

export const PIXEL_JAVA_SCRIPT_BASE_URL = "https://secure.adnxs.com/";
