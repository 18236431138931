/* eslint-disable func-names */
/**
 * Provides Authorization-related functionality, primarily via computed props
 * which define what the current user can do via their roles in the current
 * account.
 *
 * This mixin is just a thin layer on top of the authz module which does all
 * the heavy lifting. It is not meant to contain the actual logic itself, so
 * that it can be refactored later, as necessary.
 */

// import { get } from 'vuex-pathify';
import store from "./../store";
const computed = {
    currentUserRoles() {
        return store.get('authentication/userRoles');
    },
    permissions() {
        const permissionList = [];
        if (this.currentUserRoles) {
            this.currentUserRoles.forEach(roleObj => {
                roleObj.role.permissions.forEach(p => {
                    permissionList.push(p.name);
                });
            })
        }
        return permissionList;
    },

};

const methods = {
    hasPermission(p) {
        if (this.permissions) {
            return this.permissions.some(per => per === p);
        }
        return false;
    },
};


export default {
    computed,
    methods,
};
